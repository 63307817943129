import { NgTemplateOutlet } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';

import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { distinctUntilChanged, filter, first, takeUntil } from 'rxjs/operators';

import { AUTH_TOKEN, filterBoolean, DetachedRouterOutlet } from '@recruitee/common';
import { TutorialDialogService } from '@recruitee/product-updates';
import { RECRUITEE_THEME_DEFAULT, ThemeUtilsService } from '@recruitee/theme';
import { RtPlatform, WindowRef } from '@recruitee/user-interface';

import { CoreEntityActions } from '../../features/core/data/store/core.store';
import { FeatureDiscoveryEntityActions } from '../../features/feature-discovery/data/store/feature-discovery.store';
import {
  MainViewEntityActions,
  mainViewSelectors,
} from '../../features/main-view/data/store/main-view.store';
import { HeaderMenuContext, IMainViewState } from '../../features/main-view/data/types';
import { profileSelectors } from '../../features/profile/data/store/profile.store';
import { ReferralsPortalProfile } from '../../features/profile/data/types';
import { BreadcrumbsComponent } from '../../shared/components/breadcrumbs/breadcrumbs.component';
import { ReferralsPortalAnalytics } from '../../shared/referrals-portal.analytics';
import { ScrollService } from '../services/scroll.service';
import { FooterComponent } from './footer/footer.component';
import { StickyFooterService } from './footer/sticky/sticky-footer.service';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';

@Component({
  selector: 'rtr-main-view',
  templateUrl: './main-view.component.html',
  styleUrls: ['./main-view.component.less'],
  providers: [ReferralsPortalAnalytics],
  standalone: true,
  imports: [
    HeaderComponent,
    BreadcrumbsComponent,
    SidebarComponent,
    DetachedRouterOutlet,
    FooterComponent,
    NgTemplateOutlet,
  ],
})
export class MainViewComponent implements OnInit, AfterViewInit, OnDestroy {
  public headerHidden: boolean = false;
  public sidebarOpen: boolean = false;
  public hasBreadcrumbs: boolean = false;
  public templateRef: TemplateRef<any> | null;

  @ViewChild('header', { static: true }) public header: ElementRef;

  public mainViewState$: Observable<IMainViewState> = this.store
    .select(mainViewSelectors.data)
    .pipe(filterBoolean);

  public profile$: Observable<ReferralsPortalProfile> = this.store
    .select(profileSelectors.data)
    .pipe(filterBoolean);

  private ngOnDestroy$ = new Subject<void>();
  private isAdmin: boolean;

  constructor(
    @Inject(AUTH_TOKEN) private token: string,
    private store: Store,
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private stickyFooterService: StickyFooterService,
    private scrollService: ScrollService,
    private rtPlatform: RtPlatform,
    private winRef: WindowRef,
    private tutorialDialogService: TutorialDialogService,
    private vcRef: ViewContainerRef,
    private actions$: Actions,
  ) {}

  // eslint-disable-next-line max-lines-per-function
  public ngOnInit(): void {
    this.mainViewState$
      .pipe(distinctUntilChanged(), takeUntil(this.ngOnDestroy$))
      .subscribe(({ sidebarOpen, headerHidden, breadcrumbs }) => {
        this.sidebarOpen = sidebarOpen;
        this.headerHidden = headerHidden;
        this.hasBreadcrumbs = breadcrumbs && !!breadcrumbs.length;
        this.cdRef.detectChanges();
      });

    this.stickyFooterService.template$.pipe(takeUntil(this.ngOnDestroy$)).subscribe(templateRef => {
      this.templateRef = templateRef;
      this.cdRef.detectChanges();
    });

    this.store.dispatch(
      MainViewEntityActions.setHeaderMenuContext({
        payload: this.token ? HeaderMenuContext.Logged : HeaderMenuContext.NotLogged,
      }),
    );

    this.profile$.pipe(filter(Boolean), first()).subscribe((profile: ReferralsPortalProfile) => {
      ThemeUtilsService.findEffectiveThemeNameFromPreferences(
        of({
          preferences: profile.preferences ?? {
            theme: RECRUITEE_THEME_DEFAULT,
            pipelinePromoteBannerDismissedOfferIds: [],
          },
        }),
      )
        .pipe(takeUntil(this.ngOnDestroy$))
        .subscribe(name => {
          ThemeUtilsService.setThemeHTMLData(name);
        });

      const { isAdmin, referralIntroUsed } = profile;
      this.isAdmin = isAdmin;

      if (!referralIntroUsed) {
        this.router.navigate(['how-it-works']);
        this.tutorialDialogService
          .open('referrals_onboarding', this.vcRef)
          ?.afterClosed.pipe(takeUntil(this.ngOnDestroy$.asObservable()))
          .subscribe(_ => {
            this.store.dispatch(
              FeatureDiscoveryEntityActions.markFeatureAsDiscovered({
                payload: { referralIntroUsed: true },
              }),
            );
          });
      }
    });

    this.store
      .select(mainViewSelectors.isMainHeaderHiddenSelector)
      .pipe(takeUntil(this.ngOnDestroy$))
      .subscribe(headerHidden => (this.headerHidden = headerHidden));
  }

  public logOut(): void {
    this.isAdmin ? this.logOutRecruiteeUser() : this.logOutNoneRecruiteeUser();
  }

  public ngAfterViewInit(): void {
    if (this.rtPlatform.IS_MOBILE) {
      this.scrollService.scrollingDown$
        .pipe(
          filter(
            _ =>
              !(
                window.innerHeight + window.scrollY >=
                document.body.offsetHeight - this.header.nativeElement.clientHeight
              ),
          ),
          distinctUntilChanged(),
          takeUntil(this.ngOnDestroy$),
        )
        .subscribe(scrollingDown => {
          const { offsetTop, clientHeight } = this.header.nativeElement;
          this.store.dispatch(
            MainViewEntityActions.toggleHeader({
              payload: scrollingDown && offsetTop > clientHeight,
            }),
          );
        });
    }
  }

  public ngOnDestroy(): void {
    this.ngOnDestroy$.next();
    this.ngOnDestroy$.complete();
  }

  private logOutRecruiteeUser(): void {
    this.store.dispatch(CoreEntityActions.logOutWithRecruitee());
  }

  private logOutNoneRecruiteeUser(): void {
    this.store.dispatch(CoreEntityActions.logOut());

    this.actions$
      .pipe(ofType(CoreEntityActions.logOutSuccess))
      .pipe(takeUntil(this.ngOnDestroy$.asObservable()))
      .subscribe({
        next: () => {
          this.winRef.nativeWindow.location.replace('/');
        },
      });
  }
}
